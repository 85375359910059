'use strict';

function SentryProvider() {
  this.$get = [
    '$window',
    function ($window) {
      return $window.Sentry;
    }
  ];
}

function ExceptionHandlerProvider($provide) {
  if (window.Sentry && window.Sentry.getCurrentHub().getClient() === undefined) {
    window.Sentry.init({
      dsn: window.SentryConfig.dsn,
      ignoreErrors: [/NS_ERROR_FAILURE/, /Blocked a frame with origin/],
      integrations: [new window.Sentry.Integrations.Angular()] // jshint ignore:line
    });
  }
  // $provide.decorator('$exceptionHandler', ['Sentry', '$delegate', exceptionHandler]);
}

function exceptionHandler(Sentry, $delegate) {
  if (!Sentry) {
    return;
  }

  return function (ex, cause) {
    Sentry.withScope((scope) => {
      scope.setExtra('cause', cause);
      Sentry.captureException(ex);
    });
    $delegate(ex, cause);
  };
}
angular
  .module('ngSentry', [])
  .provider('Sentry', SentryProvider)
  .config(['$provide', ExceptionHandlerProvider]);

const angularPattern = /^\[((?:[$a-zA-Z0-9]+:)?(?:[$a-zA-Z0-9]+))\] (.+?)\n(\S+)$/;

if (window.Sentry) {
  window.Sentry.configureScope((scope) => {
    scope.addEventProcessor(async (event) => {
      // We only care about mutating an exception
      let exception = event.exception;
      if (exception) {
        exception = exception.values[0];
        const matches = angularPattern.exec(exception.value);

        if (matches) {
          // This type now becomes something like: $rootScope:inprog
          exception.type = matches[1];
          exception.value = matches[2];
          event.message = exception.type + ': ' + exception.value;
          // auto set a new tag specifically for the angular error url
          event.extra.angularDocs = matches[3].substr(0, 250);
        }
      }
      return event;
    });
  });
}
